import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import User from "../views/Users";
import NewPost from "../views/NewPost";
import Posts from "../views/Posts";
import NewUser from "../views/NewUser";
import Settings from "../views/Settings";
import Contact from "../views/Contact";
import Forms from "../views/ContactForms";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: true
    },
    component: Home
  },
    {
        path: '/users',
        name: 'Users',
        meta: {
            auth: true
        },
        component: User
    },
    {
        path: '/users/new',
        name: 'NewUser',
        meta: {
            auth: true
        },
        component: NewUser
    },
    {
        path: '/posts/:type',
        name: 'Posts',
        meta: {
            auth: true
        },
        component: Posts
    },
    {
        path: '/posts/:type/new',
        name: 'NewPost',
        meta: {
            auth: true
        },
        component: NewPost
    },
    {
        path: '/posts/:type/:id',
        name: 'EditPost',
        meta: {
            auth: true
        },
        component: NewPost
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            auth: true
        },
        component: Settings
    },
    {
        path: '/contactform',
        name: 'Contact',
        meta: {
            auth: true
        },
        component: Contact
    },
    {
        path: '/forms/:type',
        name: 'Forms',
        meta: {
            auth: true
        },
        component: Forms
    },
    {
        path: '/forms/:type/:id',
        name: 'EditForm',
        meta: {
            auth: true
        },
        component: Contact
    },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
})

export default router
