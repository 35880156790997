<template>
    <dashboard-layout>
        <template slot="actions">
            <v-btn color="secondary" class="rounded-0 mr-2" @click="save(false)" v-if="post_published">Omzetten naar concept</v-btn>
            <v-btn color="primary" class="rounded-0" @click="save(true)" v-if="post_published">Updaten</v-btn>
            <v-btn color="secondary" class="rounded-0 mr-2" @click="save(false)" v-if="!post_published">Opslaan als concept</v-btn>
            <v-btn color="primary" class="rounded-0" @click="save(true)" v-if="!post_published">Publiceren</v-btn>
        </template>
        <v-row>
            <v-col cols="10">
                <v-row>
                    <v-col>
                        <v-card rounded="0">
                            <v-tabs
                                v-model="tab"
                                vertical
                            >
                                <v-tab
                                    v-for="(editor, editorIdx) in editors"
                                    :key="editorIdx"
                                >
                                    {{ editor.language.name }}
                                </v-tab>
                                <v-tabs-items v-model="tab" vertical>
                                    <v-tab-item
                                        v-for="(editor, editorIdx) in editors"
                                        :key="editorIdx"
                                        :transition="false"
                                    >
                                        <v-card>
                                            <v-card-text>
                                                <v-text-field single-line label="Titel"
                                                              :value="editor.title" outlined
                                                              hide-details class="mb-2 display-1"
                                                              @change="setEditorTitle({idx: editorIdx, title: $event})"
                                                ></v-text-field>
                                                <v-text-field single-line label="Subtitel"
                                                              outlined
                                                              hide-details
                                                              dense
                                                              class="mb-5"
                                                              :value="editor.subtitle"
                                                              @change="setEditorSubtitle({idx: editorIdx, subtitle: $event})"
                                                ></v-text-field>
                                                <content-editor :value="editor.content" @input="setEditorContent({idx: editorIdx, content: $event})"></content-editor>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>Media</v-card-title>
                            <v-card-text>
                                <media-selector :value="media" @input="syncMedia($event)"></media-selector>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="2">
                <v-row>
                    <v-col>
                        <v-card flat>
                            <span class="text-overline">Featured Image</span>
                            <featured-image-editor :value="featuredImage" @input="setFeaturedImage({value: $event})"></featured-image-editor>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card flat>
                            <span class="text-overline">Categorie</span>
                            <v-select label="Categorie"
                                      :items="categories"
                                      :value="selectedCategory"
                                      @change="setSelectedCategory($event)"
                                      item-value="id"
                                      item-text="text"
                            ></v-select>
                            <span class="text-overline">Sortering</span>
                            <v-select
                                label="Plaats nieuw bericht"
                                :items="placement"
                                :value="newPlacement"
                                @change="setNewPlacement($event)"
                                :disabled="!isNew"
                            >

                            </v-select>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar
            v-if="snackbar"
            :value="true"
            @input="hideSnackbar"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            top right vertical
        >
            {{ snackbar.message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="$router.push({name: 'Posts'})"
                >
                    Terug naar menu
                </v-btn>
            </template>
        </v-snackbar>
    </dashboard-layout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import FeaturedImageEditor from "../components/FeaturedImageEditor";
import MediaSelector from "../components/MediaSelector";
import ContentEditor from "../components/ContentEditor";
export default {
    name: "NewPost.vue",
    components: {ContentEditor, FeaturedImageEditor, MediaSelector },
    data: () => ({
        tab: 0,
        tabEditor: 0,
        toggle_none: null,
        placement: [
            {value: 0, text: "Onderaan"},
            {value: 1, text: "Bovenaan"},
        ],
        currentPlacement: 0,
    }),
    computed: {
        ...mapGetters({
            isNew: 'posteditor/new',
            newPlacement: 'posteditor/newPlacement',
            languages: 'config/languages',
            editors: 'posteditor/editors',
            featuredImage: 'posteditor/featuredImage',
            media: 'posteditor/media',
            saveData: 'posteditor/saveData',
            snackbar: 'posteditor/snackbar',
            categories: 'posteditor/categories',
            selectedCategory: 'posteditor/selectedCategory',
            post_published: 'posteditor/post_published'
        })
    },
    methods: {
        ...mapActions({
            setEditorContent: 'posteditor/setEditorContent',
            setEditorTitle: 'posteditor/setEditorTitle',
            setEditorSubtitle: 'posteditor/setEditorSubtitle',
            setFeaturedImage: 'posteditor/setFeaturedImage',
            setNewPlacement: 'posteditor/setNewPlacement',
            syncMedia: 'posteditor/syncMedia',
            save: 'posteditor/save',
            hideSnackbar: 'posteditor/hideSnackbar',
            setSelectedCategory: 'posteditor/setSelectedCategory',
            setPublished: 'posteditor/setPublished',
        })
    },
    async mounted() {
        await this.$store.dispatch('posteditor/initialize', {type: this.$route.params.type});
        if (this.$route.params.id)
            await this.$store.dispatch('posteditor/load', {id: this.$route.params.id});
    }
}
</script>

<style scoped>

    .new-upload {
        border: 3px solid gray;
        height: 100px;
        width: 100px;
        cursor: pointer;
    }
</style>
