export const PLACEMENT_LAST = 0;
export const PLACEMENT_FIRST = 1;

export default {
    id: 0,
    new: true,
    newPlacement: PLACEMENT_LAST,
    content: [],
    media: [],
    type: "",
    featuredImage: null,
    snackbar: null,
    categories: [],
    selectedCategory: null,
    post_published: false,
}
