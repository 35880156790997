<template>
    <v-dialog v-model="value" :max-width="maxWidth" :max-height="maxHeight" persistent>
        <v-card>
            <v-card-title>Afbeelding aanpassen</v-card-title>
            <v-card-text>
                <vue-cropper
                    ref="cropper"
                    v-if="cropMedia"
                    :src="cropMedia.url"
                    :guides="false"
                    :view-mode="0"
                    :auto-crop="true"
                    :auto-crop-area="1"
                    :min-container-width="250"
                    :min-container-height="180"
                    :background="true"
                    :modal="true"
                    :center="false"
                    :highlight="true"
                    :aspect-ratio="16/9"
                >

                </vue-cropper>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Annuleren</v-btn>
                <v-btn text color="primary" @click="save">Opslaan</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {makeMedia} from "../utils/media";
import VueCropper from "vue-cropperjs";

export default {
    name: "MediaCropDialog",
    props: ['value', 'media'],
    components: {VueCropper},
    data() {
        return {
            cropMedia: null,
            maxWidth: "1000px",
            maxHeight: "0.9vh"
        }
    },
    methods: {
        async save() {
            this.$refs.cropper
                .getCroppedCanvas({
                    maxWidth: 1920,
                    maxHeight: 1080
                }).toBlob(async blob => {
                const file = new File([blob], this.cropMedia.file.name, {type: this.cropMedia.file.type});
                this.$emit('input', false);
                this.$emit('input:media', await makeMedia({file}));
                this.cropMedia = null;
            }, "image/jpeg", 0.95);
        },
        close() {
            this.$emit('input', false);
            this.cropMedia = null;
        }
    },
    watch: {
        media(newMedia) {
            this.cropMedia = newMedia;
            if (this.$refs.cropper)
                this.$refs.cropper.initCrop();
        }
    }
}
</script>

<style scoped>

</style>
