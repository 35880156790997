<template>
    <v-card flat class="d-flex">
        <v-card max-width="100" class="mr-3" v-for="(media, mediaIdx) in value" :key="mediaIdx" style="position:relative;">
            <v-img :src="media.url" max-width="100" max-height="100" width="100" height="100"></v-img>
            <v-btn small
                   class="delbtn"
                   color="error" @click="removeMedia(mediaIdx)"><v-icon small>mdi-minus</v-icon></v-btn>
        </v-card>
        <v-card max-height="100" max-width="100" width="100" height="100"
                class="new-upload mr-3 d-flex justify-center align-center text-center"
                @click="$refs.uploader.click()"
        >
            <input type="file" ref="uploader" class="d-none" @change="uploaderChange" />
            <v-icon>mdi-plus</v-icon>
        </v-card>
        <media-crop-dialog v-model="dialog" :media="cropperMedia" @input:media="saveMedia"></media-crop-dialog>
    </v-card>
</template>

<script>
import {makeMedia} from "../utils/media";
import MediaCropDialog from "./MediaCropDialog";

export default {
    name: "MediaSelector",
    components: {MediaCropDialog},
    props: ['value'],
    data() {
        return {
            cropperMedia: null,
            dialog: false,
        }
    },
    methods: {
        async uploaderChange(e) {
            this.cropperMedia = await makeMedia({file: e.target.files[0]});
            this.dialog = true;
            e.target.value = null;
        },
        removeMedia(idx) {
            let tmp = this.value;
            let rem = tmp.splice(idx, 1)[0];
            this.$emit('delete', rem);
            this.$emit('input', tmp);
        },
        saveMedia(media) {
            this.$emit('select', media);
            this.$emit('input', [...this.value, media]);
        }
    }

}
</script>

<style scoped>
.new-upload {
    border: 3px solid gray;
    height: 100px;
    width: 100px;
    cursor: pointer;
}
.delbtn {
    position:absolute;
    top: 10px;
    right: 10px;
    width:28px !important;
    height:28px !important;
    max-width: 28px !important;
    max-height: 28px !important;
    min-width: 28px !important;
}
</style>
