<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(shortcode, i) in shortcodes" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            {{ shortcode.name }}
                            <template v-slot:actions>
                                <v-btn icon color="error"><v-icon color="error" @click.stop="() => {}">mdi mdi-delete</v-icon></v-btn>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-textarea outlined></v-textarea>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card flat>
                    <v-btn color="success"><v-icon>mdi mdi-plus</v-icon> Nieuw</v-btn>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import Vue from "vue";

export default {
    name: "ShortcodeSettingsTab",
    tabName: "Shortcodes",
    key: "shortcodes",
    icon: "mdi mdi-code-brackets",
    data() {
        return {
            shortcodes: [],
            selectedShortcode: null,
        }
    },

    async load(data) {
        console.log(this);
        Vue.set(this, 'shortcodes', data);
    },
    async save() {
        console.log("lol");
    }
}
</script>

<style scoped>

</style>
