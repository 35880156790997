import {serialize} from "object-to-formdata";

export default {
    id: state => state.id,
    new: state => state.isNew,
    name: state => state.name,
    email: state => state.email,
    password: state => state.password,
    passwordRepeat: state => state.passwordRepeat,
    saveData: state => serialize({
        id: state.id,
        name: state.name,
        email: state.email,
        password: state.password,
        passwordRepeat: state.passwordRepeat
    })
}
