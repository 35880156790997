export default {
    setId: (state, payload) => { state.id = payload; state.isNew = false },
    setName: (state, payload) => state.name = payload,
    setEmail: (state, payload) => state.email = payload,
    setPassword: (state, payload) => state.password = payload,
    setPasswordRepeat: (state, payload) => state.passwordRepeat = payload,
    reset(state) {
        state.id = 0;
        state.isNew = true;
        state.name = "";
        state.email = "";
        state.password = null;
        state.passwordRepeat = null;
    }
}
