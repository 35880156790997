<template>
    <dashboard-layout>
        <v-toolbar
            flat
            color="primary"
            dark
        >
            <v-toolbar-title>System Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="save">Save</v-btn>
        </v-toolbar>
        <v-tabs vertical>
            <template v-for="(tab, tabIdx) in tabs">
                <v-tab :key="tabIdx">
                    <v-icon left small>{{ tab.icon }}</v-icon>
                    {{ tab.tabName }}
                </v-tab>
                <v-tab-item :key="tabIdx">
                    <v-card flat>
                        <component :key="tabIdx" :is="tab"></component>
                    </v-card>
                </v-tab-item>
            </template>
        </v-tabs>

    </dashboard-layout>
</template>

<script>
import Vue from "vue";
export default {
    name: "Settings.vue",
    data() {
        return {
            tabs: []
        }
    },
    methods: {
        save() {
            for(let tab of this.tabs) {
                tab.save();
            }
        }
    },
    beforeMount() {
        const components = require.context('@/views/settings', true, /[A-Z]\w+\.(vue)$/);
        components.keys().forEach(filename => {
            const name = filename.split('.')[1].split('/')[1]
            const Comp = components(filename).default;
            const VComp = Vue.component(name, Comp);
            this.tabs.push({...Comp, name, $component: VComp});
        })
    },
    async mounted() {
        let resp = await window.axios.get('/settings', {params: {tabs: this.tabs.map(x => x.key)}});
        for(let tab of this.tabs) {
            console.log(tab.$component);
            await tab.$component.load(resp.data[tab.key]);
        }
    }
}
</script>

<style scoped>

</style>
