import {PLACEMENT_LAST} from "./state";

export default {
    addEditor(state, {language, title, content}) {
        state.content.push({title, language, content});
    },
    setContent(state, {idx, content}) {
        state.content[idx].content = content;
    },
    setTitle(state, {idx, title}) {
        state.content[idx].title = title;
    },
    setSubtitle(state, {idx, subtitle}) {
        state.content[idx].subtitle = subtitle;
    },
    setFeaturedImage(state, {value}) {
        state.featuredImage = value;
    },
    setId(state, {id}) {
        state.id = id;
        state.new = false;
    },
    setPublished(state, published){
        state.post_published = published;
    },
    setPlacement(state, placement){
        state.newPlacement = placement;
    },
    setType(state, {type}) {
        state.type = type;
    },
    syncMedia(state, media) {
        state.media = media;
    },
    showSnackbar(state, snackbar) {
        state.snackbar = snackbar;
    },
    setCategories(state, categories) {
        state.categories = categories;
    },
    setSelectedCategory(state, category) {
        state.selectedCategory = category;
    },
    clear(state) {
        state.id = 0;
        state.content = [];
        state.media = [];
        state.featuredImage = null;
        state.new = true;
        state.newPlacement = PLACEMENT_LAST;
        state.type = "";
        state.snackbar = null;
        state.categories = [];
        state.selectedCategory = null;
        state.post_published = 0;
    }
}
