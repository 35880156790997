import {omit} from "@/utils/omit";
import { serialize } from 'object-to-formdata';

export default {
    editorByLanguage: (state) => (code) => {
        return state.content[code];
    },
    editors: (state) => {
        return state.content;
    },
    id: state => state.id,
    featuredImage: state => state.featuredImage,
    media: state => state.media,
    type: state => state.type,
    post_published: state => state.post_published,
    snackbar: state => state.snackbar,
    newPlacement: state => state.newPlacement,
    categories: (state) => {
        let out = [{id: null, text: "Algemeen"}];
        for(let category of state.categories) {
            out.push({id: category.id, text: category.content.find(x => x.code === 'nl').name});
        }
        return out;
    },
    selectedCategory: state => state.selectedCategory,
    saveData: (state) => {
        let obj = {
            id: state.id,
            content: state.content.map((v) => ({
                ...v,
                language: v.language.code,
                title: v.title || "",
                subtitle: v.subtitle || "",
                content: v.content || ""
            })),
            featuredImage: omit(state.featuredImage, ["_", "url"]),
            media: state.media.map((v) => omit(v, ["_", "url"])),
            type: state.type,
            post_category_id: state.selectedCategory,
            published: state.post_published ? "1": "0"
        }
        if (!state.new)
            obj._method = "PATCH";
        if (state.new)
            obj.placement = state.newPlacement;
        return serialize(obj, {indices: true})
    } ,
    new: state => state.new
}
