<template>
    <v-dialog :value="showDialog" max-width="450">
        <v-form ref="form">
            <v-card>
                <v-card-title>Mews</v-card-title>
                <v-card-text>
                    Voer een mews distributor code in
                    <v-text-field placeholder="aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" v-model="value" :rules="[rules.counter, rules.pattern]"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="submit">Verstuur</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    name: "MewsDialog",
    data: () => ({
        showDialog: true,
        value: "",
        rules: {
            counter: value => value.length === 36 || 'Moet 36 karakters lang zijn',
            pattern: value => {
                const pattern = /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/;
                return pattern.test(value) || "Geen geldige code";
            }
        }
    }),
    props: ['ctx'],
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                const transaction = this.ctx.editor.state.tr.insertText(`[mews code="${this.value}"]`);
                this.ctx.editor.view.dispatch(transaction);
                this.showDialog = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
