<template>
    <v-dialog v-model="showDialog"  max-width="450">
        <v-card>
            <v-card-title>Bestand bijvoegen</v-card-title>
            <v-card-text>
                <v-file-input v-model="file" label="Kies een bestand..."></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showDialog = false">Annuleren</v-btn>
                <v-btn text color="primary" @click="upload">Upload</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "UploadDialog.vue",
    data() {
        return {
            showDialog: true,
            file: null
        }
    },
    props: ['ctx'],
    methods: {
        async upload() {
            let fd = new FormData();
            fd.append('attachment', this.file);
            let resp = await window.axios.post('upload', fd);
            const transaction = this.ctx.editor.state.tr.insertText(resp.data);
            this.ctx.editor.view.dispatch(transaction);
            this.showDialog = false;
            this.file = null;
        }
    }
}
</script>

<style scoped>

</style>
