<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="users" class="elevation-1"
                              :options.sync="pagination" :server-items-length="totalItems"
                              :footer-props="{itemsPerPage: rowsPerPage, page}" dense >
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>

export default {
    name: "User",
    data() {
        return {
            loading: false,
            headers: [
                {text: "name", value: "name"},
                {text: "email", value: "email"},
            ],
            page: 1
        }
    },
    computed: {
        totalItems(){
            return this.$store.getters['users/totalItems']
        },
        rowsPerPage(){
            return this.$store.getters['users/rowsPerPage']
        },
        users() { return this.$store.getters['users/users'] },
        pagination: {
            get:function() {
                return this.$store.getters['users/pagination'];
            },
            set:function(value){
                this.$store.dispatch('users/getUsers',value)
            }
        },
    },
    watch: {
        pagination: {
            handler () {

                this.loading = true
                this.$store.dispatch('users/getUsers',this.page)
                    .then(() => {
                        this.loading = false
                    })
            },
            update(){
                console.log("update");
                this.loading = true
                this.$store.dispatch('users/getUsers',this.page)
                    .then(() => {
                        this.loading = false
                    })
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
