<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <p-data-table :headers="headers" :items="posts" :loading="loading" class="elevation-1"
                              :options.sync="pagination" :server-items-length="totalItems"
                              :footer-props="{itemsPerPage: rowsPerPage, page}" dense
                              @dragged="handleDrag" :disable-sorting="disableSorting"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                :to="{name: 'NewPost'}"
                            >
                                <v-icon>mdi-plus</v-icon> Nieuw
                            </v-btn>
                        </v-toolbar>
                        <v-dialog
                            v-model="dialog"
                            max-width="640px"
                        >
                            <v-card>
                                <v-card-title>Verwijder Post</v-card-title>
                                <v-card-text>Weet je zeker dat je deze post wilt verwijderen?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="dialog = false">Annuleer</v-btn>
                                    <v-btn text color="primary" @click="deleteConfirm">Verwijder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:item.drag>
                        <v-icon class="draghandle" v-if="!disableSorting">mdi-drag</v-icon>
                    </template>
                    <template v-slot:item.id="{item}">
                        {{ item.id }}
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small @click="$router.push({name: 'EditPost', params: { id: item.id }})">mdi-pencil</v-icon>
                        <v-icon small @click="showDeleteDialog(item.id)">mdi-delete</v-icon>

                        <v-icon small @click="orderPostUp(item.id)" v-if="!disableSorting">mdi-arrow-up</v-icon>
                        <v-icon small @click="orderPostDown(item.id)" v-if="!disableSorting">mdi-arrow-down</v-icon>

                    </template>
                    <template v-slot:item.published="{item}">
                        {{ item.published ? "Gepubliceerd" : "Concept" }}
                    </template>
                </p-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>

import {mapGetters} from "vuex";
import {pluralize} from "@/utils/pluralizer";
import {ucfirst} from "@/utils/stringHelpers";
import PDataTable from "../components/PDataTable";

export default {
    name: "Posts",
    components: {PDataTable},
    data() {
        return {
            loading: false,
            dialog: false,
            deleteItem: null,
            disableSorting: false,
            headers: [
                {text: "", value: "drag", sortable: false, orderable: false, width: "1%"},
                {text: "id", value: "id"},
                {text: "Titel", value: "title"},
                {text: "Auteur", value: "author"},
                {text: "Aangemaakt op", value: "created_at"},
                {text: "Laatst bewerkt", value: "updated_at"},
                {text: "Status", value: "published"},
                {text: "Acties", value: "actions"},
            ],
            page: 1
        }
    },
    mounted() {
        const config = this.$store.getters['config/getConfig'];
        const type = this.$route.params.type;
        if (config.post_meta[type]) {
            if (config.post_meta[type].order) {
                this.disableSorting = true;
            }
        }
    },
    methods: {
        async showDeleteDialog(id) {
            this.deleteItem = id;
            this.dialog = true;
        },
        async deleteConfirm() {
            try {
                await this.$axios.delete(`posts/${this.deleteItem}`, {_method: 'delete'});
                await this.$store.dispatch('posts/getPosts', {page: this.page, type: this.$route.params.type})
            } finally {
                this.dialog = false;
            }
        },
        async orderPostUp(id) {
            try {
                this.loading = true

                await this.$axios.post(`posts/${id}/orderup`);
            } finally {
                await this.$store.dispatch('posts/getPosts', {page: this.page, type: this.$route.params.type})
                this.loading = false
            }
        },
        async orderPostDown(id) {
            try {
                this.loading = true

                await this.$axios.post(`posts/${id}/orderdown`);
            } finally {
                await this.$store.dispatch('posts/getPosts', {page: this.page, type: this.$route.params.type})
                this.loading = false
            }
        },
        logme(a) {
            console.log(a);
        },
        async handleDrag(ev) {
            let row = this.posts.splice(ev.oldIndex, 1)[0];
            this.posts.splice(ev.newIndex, 0, row);
            if (ev.newIndex !== ev.oldIndex) {
                if (ev.oldIndex > ev.newIndex) {
                    let beforeRow = this.posts[ev.newIndex + 1];
                    console.log("up", beforeRow.id);
                    await this.$axios.post(`posts/${row.id}/orderto`, {
                        beforeId: beforeRow.id
                    });
                } else {
                    let afterRow = this.posts[ev.newIndex - 1];
                    await this.$axios.post(`posts/${row.id}/orderto`, {
                        afterId: afterRow.id
                    });
                }
            }

        }
    },
    computed: {
        ...mapGetters({
            totalItems: 'posts/totalItems',
            rowsPerPage: 'posts/rowsPerPage',
            posts: 'posts/posts'
        }),
        pagination: {
            get:function() {
                return this.$store.getters['posts/pagination'];
            },
            set:function(value){
                this.loading = true;
                this.$store.dispatch('posts/getPosts',{page: value, type: this.$route.params.type}).then(() => this.loading = false);
            }
        },
        toolbarTitle() {
            return ucfirst(pluralize(this.$route.params.type.replace(/_-/, '')));
        }
    },
    watch: {
        pagination: {
            handler () {
                this.loading = true
                this.$store.dispatch('posts/getPosts',{page: this.page, type: this.$route.params.type})
                    .then(() => {
                        this.loading = false
                    })
            },
            update(){
                this.loading = true
                this.$store.dispatch('posts/getPosts',{page: this.page, type: this.$route.params.type})
                    .then(() => {
                        this.loading = false
                    })
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
