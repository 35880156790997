<template>
    <v-container>
        <v-row>
            <v-col>
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th></th>
                            <th colspan="4">Translation</th>
                        </tr>
                        <tr>
                            <th>Keyword</th>
                            <th>Dutch</th>
                            <th>English</th>
                            <th>German</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(translation, i) in translations" :key="i">
                            <th><input type="text" v-model="translation.key" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></th>
                            <td><input type="text" v-model="translation.nl" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                            <td><input type="text" v-model="translation.en" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                            <td><input type="text" v-model="translation.de" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                            <td><v-btn x-small color="error" small block><v-icon x-small>mdi mdi-minus</v-icon> Delete</v-btn></td>
                        </tr>
                    <tr>
                        <th><input type="text" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></th>
                        <td><input type="text" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                        <td><input type="text" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                        <td><input type="text" style="background-color: white;width:100%; border: 1px solid gray; border-radius: 5px;"/></td>
                        <td>
                            <v-btn x-small color="success" small block><v-icon x-small>mdi mdi-plus</v-icon> New</v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TranslationsSettingsTab",
    tabName: "Translations",
    key: "translations",
    icon: "mdi mdi-translate",
    data() {
        return {
            translations: [
                {key: "prijs_per_persoon", nl: "prijs per persoon vanaf € :prijs", en: "Price per person from € :price", de: "Preis pro Person ab :price €"}
            ]
        }
    },
    async load() {},
    async save() {
        console.log("lol");
    }
}
</script>

<style scoped>

</style>
