<template>
    <v-data-table v-bind="[$props,$attrs]"
    v-on="$listeners"
    ref="table">
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
    </v-data-table>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: "PDataTable",
    props: {
        disableSorting: {
            type: Boolean,
            default: undefined
        }
    },
    mounted() {
        let self = this;
        if (!this.disableSorting) {
            Sortable.create(this.$refs.table.$el.querySelector("tbody"), {
                onEnd(ev) {
                    self.$emit('dragged', ev);
                },
                handle: ".draghandle"
            });
        }
    }
}
</script>

<style scoped>

</style>
