export default {
    updatePosts(state,payload){
        state.posts = payload.data
        if (payload.meta) {
            state.pagination.page = payload.meta.current_page;
            state.pagination.rowsPerPage = payload.meta.per_page;
            state.totalItems = payload.meta.total;
        } else {
            state.pagination.page = 1;
            state.pagination.rowsPerPage = -1;
            state.totalItems = state.posts.length;
        }

    },
}
