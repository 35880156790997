
const CONSONANT             = '[bcdfghjklmnpqrstvwxyz]';
const VOWEL                 = '[aeiou]';
const VOWEL_EXCEPT_I        = '[aeou]';
const DOUBLE_SAME_VOWEL     = 'aa|ee|oo|uu';
const DOUBLE_SAME_CONSONANT = 'bb|dd|ff|gg|kk|ll|mm|nn|pp|rr|ss|tt';

/* eslint-disable no-useless-escape */
const endings = {
    // special exceptions
    'ei(eren)?'                : ['ei', 'eieren'],
    'vlo(oi|oien)?'            : ['vlo', 'vlooien'],
    'kal(f|veren)'             : ['kalf', 'kalveren'],
    'media'                    : ['media', 'media'],
    'cris(is|es)'              : ['crisis', 'crises'],
    'tikel(s|en)?'             : ['tikel', 'tikelen'],
    'catalog(us|i)'            : ['catalogus', 'catalogi'],
    'geni(e|us|i[eë]n)'        : ['genius', 'genieën'],
    'aanb(od|iedingen)'        : ['aanbod', 'aanbiedingen'],
    'gel(id|ederen)'           : ['gelid', 'gelederen'],
    'gedrag(ingen)'            : ['gedrag', 'gedragingen'],
    'gen(ot|ietingen)'         : ['genot', 'genietingen'],
    '(adres|bordes)(sen)?'     : ['$1', '$1sen'],
    '^(les)(sen)?'             : ['$1', '$1sen'],
    '^lof'                        : ['lof', 'lofbetuigingen'],
    'lof((uiting|betuiging)(en))' : ['lof$2', 'lof$2en'],

    // common special
    '(\d)s?'        : [ '$1', '$1s' ],
    'ties?'         : ['tie', 'ties'],
    'ie([eë]n)?'    : ['ie', 'ieën'],
    'taxi\'?s?'     : ['taxi', 'taxi\'s'],
    // vrede uitzondering!
    '^rede(nen)?'   : ['$1ede', '$1edenen'],
    '(C)ende(nen)?' : ['$1ende', '$1endenen'],

    // english
    '(rch)(es)?' : [ '$1', '$1es' ],
    '(url|set|uence|che|age)s?' : [ '$1', '$1s' ],
    '(ea|ai|ia)([dlmr])s?' : ['$1$2', '$1$2s'],
    'ngles?'    : [ 'ngle', 'ngles' ],
    'ayout(s)?' : ['ayout', 'ayouts'],
    '(V)ys?'    : ['$1y', '$1ys'],
    'ss(es)?'   : ['ss', 'sses'],
    '(C)end'    : ['$1end', '$1ends'],
    'chats?'    : ['chat', 'chats'],
    'shops?'    : ['shop', 'shops'],
    'tags?'     : ['tag', 'tags'],
    'blogs?'     : ['blog', 'blogs'],
    'slides?'     : ['slide', 'slides'],

    // french
    '(pardon|coupon)s?' : ['$1', '$1s'],
    '(nn|V[cpqt])uis?'  : ['$1ui', '$1uis'],
    'eaus?' : [ 'eau', 'eaus' ],

    // wortel -> wortels
    // partner -> partners
    '(V)(C{1,3})e([rlm])s?' : [ '$1$2e$3', '$1$2e$3s' ],

    // lade -> laden
    // bode -> bodes
    'ade' : ['ade', 'aden'],
    'ode' : ['ode', 'odes'],

    // museum -> musea
    'se(a|um)' : ['seum', 'sea'],

    // boe -> boes
    // koe -> koeien
    // groei -> groeien
    '(boe)(s)?'  : ['$1', '$1s'],
    '(oe)(ien)?' : ['$1', '$1ien'],
    '(oei)(en)?' : ['$1', '$1en'],

    // medium -> mediums
    'iums?' : ['ium', 'iums'],
    // pagina -> pagina's
    'ina(\'s)?' : ['ina', 'ina\'s'],
    // video -> videos
    '([aeo]{2})s?' : ['$1', '$1s'],

    // exception: paragrafen / parafen / typen
    'paragra(af|fen)' : ['paragraaf', 'paragrafen'],
    'para(af|fen)'    : ['paraaf', 'parafen'],
    'auteurs?'        : ['auteur', 'auteurs'],
    'coureurs?'       : ['coureur', 'coureurs'],
    'typen?'          : ['type', 'typen'],

    // hoes -> hoezen
    // graaf -> graven
    // aas -> azen
    '(ie|oe|eu|ui|ei|ij)(f|ven)'     : ['$1f', '$1ven'],
    '(ie|oe|eu|ui|ei|ij|iel)(s|zen)' : ['$1s', '$1zen'],
    '(DSV)f' : ['$1f', ':SINGLE:ven'],
    '(V)ven' : ['$1$1f', '$1ven'],
    '(DSV)s' : ['$1s', ':SINGLE:zen'],
    '(V)zen' : ['$1$1s', '$1zen'],

    // groet -> groeten
    // kleur -> kleuren
    '(oe|eu|ie|ij|ou)(C)(en)?' : ['$1$2', '$1$2en'],

    // bedrag -> bedragen
    'edrag(en)?'  : ['edrag', 'edragen'],

    // dak -> daken
    // pad -> paden (forget about the toad)
    '(dal|dak|pad|slot|vat|weg|aardappel)(en)?' : [ '$1', '$1en' ],
    // aap -> apen etc, loop -> lopen
    // materiaal -> materialen
    '(DSV)([dgklmnprt])'      : ['$1$2', ':SINGLE:$2en'],
    '(C)(V!I)([dgklmnprt])en' : ['$1$2$2$3', '$1$2$3en'],
    'i(V!I)([dgklmnprt])en'   : ['i$1$1$2', 'i$1$2en'],
    '^a([gklpr])en'           : ['aa$1', 'a$1en'],
    '^o([gr])en'              : ['oo$1', 'o$1en'],

    // graf -> graven
    'gra(f|ven)'  : ['graf', 'graven'],

    // vis -> vissen
    // kanon -> kanonnen
    // do not include 'en' matches here
    '(V)([bdfgklmprst])(en)?'      : ['$1$2', '$1$2$2en'],
    '(V)([bdfklmnprst])([aoui])n' : ['$1$2$3n', '$1$2$3nnen'],
    '(V)(DSC)en'                  : ['$1:SINGLE:', '$1$2en'],
    // bon -> bonnen
    '^(C)([aeoui])n'  : ['$1$2n', '$1$2nnen'],

    // ..en fallback assume plural
    '(C)en'  : ['$1', '$1en'],

    // tekst -> teksten
    '(V)kst(en)?' : [ '$1kst', '$1ksten' ],
    'ijst(en)?' : [ 'ijst', 'ijsten' ],

    // abbreviations and oddities
    '([bcdfghjklmnpqrtvwxyz]{3})s?' : [ '$1', '$1s' ],

    '([aeo])s?' : ['$1', '$1s'],
};

function findEndingBasedMatch(word) {
    for(let ending of Object.keys(endings)) {
        const forms = endings[ending];
        let specialCase = null;
        if (ending.includes('DSC'))
            specialCase = DOUBLE_SAME_CONSONANT;
        else if (ending.includes('DSV'))
            specialCase = DOUBLE_SAME_VOWEL;

        ending = ending.replace(/DSC/g, DOUBLE_SAME_CONSONANT);
        ending = ending.replace(/DSV/g, DOUBLE_SAME_VOWEL);
        ending = ending.replace(/C/g, CONSONANT);
        ending = ending.replace(/V!I/g, VOWEL_EXCEPT_I);
        ending = ending.replace(/V/g, VOWEL);

        const lastPartReg = new RegExp(`^(.*)${ending}$`, 'i');
        let matches = word.match(lastPartReg);
        if (matches) {
            let newSingularEnd = word.replace(new RegExp(`${ending}$`, 'i'), forms[0]);
            let newPluralEnd = word.replace(new RegExp(`${ending}$`, 'i'), forms[1]);

            let fixedForms = {
                singular: newSingularEnd,
                plural: newPluralEnd
            };

            if (specialCase === DOUBLE_SAME_CONSONANT || specialCase === DOUBLE_SAME_VOWEL) {
                let single = '';
                for (let x=matches.length-1; x > 0; x--) {
                    if (matches[x].match(new RegExp(specialCase, 'i'))) {
                        single = matches[x].substr(0, 1);
                        break;
                    }
                }

                fixedForms.singular = fixedForms.singular.replace(':SINGLE:', single);
                fixedForms.plural = fixedForms.plural.replace(':SINGLE:', single);
            }
            return fixedForms;
        }
    }
}

export function pluralize(word) {
    let forms = findEndingBasedMatch(word);

    if (!forms) {
        if (word.match(/s$/)) return word;
        return word + "en";
    }
    return forms.plural;
}

export function singularize(word) {
    let forms = findEndingBasedMatch(word);
    if (!forms) return word;
    return forms.singular;
}
