<template>
    <v-container>
        <v-row>
            <v-col md="4">
                <v-text-field label="Site name"></v-text-field>
                <v-text-field label="Site url"></v-text-field>
            </v-col>
        </v-row>
        <v-form>

        </v-form>
    </v-container>
</template>

<script>
export default {
    name: "GeneralSettings",
    tabName: "General",
    key: "general",
    icon: "mdi mdi-cog",
    async load() {},
    async save() {
        console.log("lol");
    },
    async mounted() {

    }

}
</script>

<style scoped>

</style>
