export async function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export async function makeMedia(opts) {
    let media = Object.assign({},{
        local: true,
        url: null,
        mime: null,
        file: null,
        _: Math.random()
    }, opts);
    if (media.file)
        media.local = true;
    else
        media.local = false;
    if (media.file && media.url == null) {
        media.url = await toBase64(media.file);
        media.mime = media.file.type;
    }

    return media;
}
