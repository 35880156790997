<template>
    <dashboard-layout>
        <v-container>
            <v-card class="pa-8">

                <v-row>
                    <v-col cols="12">
                        <h2 class="text-center">Contact formulier</h2>
                        <v-card-subtitle class="text-center">Hier kunt u de instellingen van het formulier bewerken.</v-card-subtitle>
                    </v-col>
                </v-row>
                <v-form>
                   <v-row>
                       <v-col cols="12">
                           <v-tabs
                               v-model="tab"
                               background-color="transparent"
                               color="blue"
                               grow
                           >
                               <v-tab>Formulier</v-tab>
                               <v-tab>Mail</v-tab>
                           </v-tabs>

                           <v-tabs-items v-model="tab">
                               <v-tab-item>
                                    <v-row>
                                        <v-col cols="12" class="mt-4">
                                            <h2>Formulier opstelling:</h2>
                                            <small>Hier kunt u de opstelling van het formulier bewerken.</small>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="name"
                                                :counter="10"
                                                label="Formulier Name: "
                                                filled
                                                required
                                                :rules="[v => !!v || 'Dit veld is verplicht']"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-textarea
                                                v-model="formContent"
                                                filled
                                                auto-grow
                                                :counter="5000"
                                                required
                                                :rules="[v => !!v || 'Dit veld is verplicht']"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                               </v-tab-item>

                               <v-tab-item>
                                   <v-row>
                                       <v-col cols="12" class="mt-4">
                                           <h2>Mailing</h2>
                                           <small>
                                               Hier kunt u de email instellingen bewerken. In de volgende velden kun je de e-mail-tags instellen:
                                           </small>
                                       </v-col>

                                       <v-col cols="12">
                                           <v-text-field
                                               v-model="sender"
                                               :counter="50"
                                               label="Aan:"
                                               filled
                                               required
                                               :rules="[v => !!v || 'Dit veld is verplicht']"
                                           ></v-text-field>
                                       </v-col>

                                       <v-col cols="6">
                                           <v-text-field
                                               v-model="environment"
                                               :counter="50"
                                               label="Van:"
                                               filled
                                               required
                                               :rules="[v => !!v || 'Dit veld is verplicht']"
                                           ></v-text-field>
                                       </v-col>

                                       <v-col cols="6">
                                           <v-text-field
                                               v-model="subject"
                                               label="Subject: "
                                               filled
                                               required
                                               :rules="[v => !!v || 'Dit veld is verplicht']"
                                           ></v-text-field>
                                       </v-col>

                                       <v-col cols="12">
                                           <v-textarea
                                               v-model="additional_headers"
                                               label="Aanvullende koppen:"
                                               required
                                               filled
                                               auto-grow
                                               :counter="500"
                                               :rules="[v => !!v || 'Dit veld is verplicht']"
                                           ></v-textarea>
                                       </v-col>

                                       <v-col cols="12">
                                           <v-textarea
                                               v-model="mail_content"
                                               label="Bericht inhoud:"
                                               required
                                               filled
                                               auto-grow
                                               :counter="2500"
                                               :rules="[v => !!v || 'Dit veld is verplicht']"
                                           ></v-textarea>
                                       </v-col>
                                   </v-row>
                               </v-tab-item>
                           </v-tabs-items>

                           <v-row justify="center">
                               <v-col cols="12">
                                   <v-btn
                                       class="success"
                                       @click="submit"
                                   >
                                       submit
                                   </v-btn>
                               </v-col>
                           </v-row>
                       </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-container>
    </dashboard-layout>
</template>

<script>
export default {
    name: 'Contactform',

    data () {
        return {
            tab: null,

            name: '',
            formContent: '',
            sender: '',
            environment: '',
            subject: '',
            additional_headers: '',
            mail_content: '',

            items: ['Formulier', 'Mail']
        }
    },
    methods: {

        async submit() {
            this.loading = true;
            try {
                await this.$axios.post('forms', {
                    name: this.name,
                    formContent: this.formContent,

                    sender: this.sender,
                    environment: this.environment,
                    subject: this.subject,
                    additional_headers: this.additional_headers,
                    mail_content: this.mail_content,
                });
                await this.$router.push({name: 'Home'});
            } catch(e) {
                if (e.response.status === 422) {
                    if (e.response.data.error) {
                        await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                    }
                }
            } finally {
                this.loading = false;
            }
        }
    },
}
</script>
