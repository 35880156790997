<template>
    <v-app>
        <template v-if="loading">
            <v-overlay :value="loading" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </template>
        <router-view v-else :key="$route.path"/>
    </v-app>

</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "App",
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapActions({
            getConfig: 'config/getConfig',
        }),
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLogged'
        })
    },
    async mounted() {
        this.loading = true;
        if (this.isLoggedIn)
            await this.getConfig();
        this.loading = false;
    }
}
</script>
