import Vue from 'vue'
import usereditor from './module-usereditor'

import posteditor from './module-posteditor'
import config from './module-config'
import posts from './module-posts'
import users from './module-users'
import auth from './module-auth'
import forms from './module-contact'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    usereditor,
    posteditor,
    config,
    posts,
    users,
    auth,
    forms,
  }
})
