<template>
    <v-card class="featured-upload d-flex align-center justify-center text-center rounded-0" @click="$refs.uploader.click()">
        <input type="file" class="d-none" ref="uploader" @input="uploaderChange" />
        <v-card-text v-if="value == null">Kies Afbeelding...</v-card-text>
        <v-img :src="value.url" height="100%" width="100%" v-else></v-img>
        <media-crop-dialog v-model="dialog" :media="cropperMedia" @input:media="saveMedia"></media-crop-dialog>
    </v-card>
</template>
<script>
import {makeMedia} from "../utils/media";
import VueCropper from "vue-cropperjs";
import MediaCropDialog from "./MediaCropDialog";

export default {
    props: ['value'],
    components: {MediaCropDialog, VueCropper},
    data() {
        return {
            dialog: false,
            cropperMedia: null,
            maxWidth: "1000px",
            maxHeight: "0.9vh"
        }
    },
    methods: {
        async uploaderChange(e) {
            this.cropperMedia = await makeMedia({file: e.target.files[0]});
            this.dialog = true;
        },
        saveMedia(m) {
            this.$emit('input', m);
        }
    },
}
</script>
<style>
.featured-upload {
    border: 3px solid gray;
    height: 150px;
    color: gray;
    cursor: pointer;
}
</style>
