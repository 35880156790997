import {MakeExtension, TextIcon} from "tiptap-vuetify";
import MewsDialog from "../components/MewsDialog";
import Vue from "vue";

export default
    MakeExtension({
        nativeExtensionName: "mews",
        icons: {
            mdi: new TextIcon("Me"),
            md: new TextIcon("Me"),
            fa: new TextIcon("Me"),
            mdiSvg: new TextIcon("Me")
        },
        tooltip: "Mews",
        isActive: () => false,
        onClick: (c) => {
            let dlg = Vue.extend(MewsDialog);
            const instance = new dlg({
                vuetify: Vue.prototype.tiptapVuetifyPlugin.vuetify,
                propsData: {
                    ctx: c
                }
            });

            instance.$mount();
            document.querySelector('body').appendChild(instance.$el)
        }
    });
