export default {
    setName: ({commit}, payload) => commit('setName', payload),
    setEmail: ({commit}, payload) => commit('setEmail', payload),
    setPassword: ({commit}, payload) => commit('setPassword', payload),
    setPasswordRepeat: ({commit}, payload) => commit('setPasswordRepeat', payload),
    save() {

    },
    async load({commit}, id) {// eslint-disable-line no-unused-vars
        let resp = await window.axios.get(`users/${id}`);
        let data = resp.data;
        commit('setId', data.id);
        commit('setName', data.name);
        commit('setEmail', data.email);
    },
    reset: ({commit}) => commit('reset')

}
