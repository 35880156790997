<template>
    <dashboard-layout>
        <v-form>
            <v-row>
                <v-col cols="12" lg="4">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                    <v-text-field label="Gebruikersnaam" single-line hide-details prepend-icon="mdi-account"
                                  :value="name" @change="setName($event.target.value)"></v-text-field>
                    <v-text-field type="email" label="E-mail" single-line hide-details
                                  prepend-icon="mdi-email" :value="email"
                                  @change="setEmail($event.target.value)"></v-text-field>
                    <v-text-field type="password" label="Wachtwoord" single-line hide-details
                                  prepend-icon="mdi-lock" :value="password" @change="setPassword($event.target.value)"
                    ></v-text-field>
                    <v-text-field type="password" label="Herhaal wachtwoord" single-line hide-details
                                  prepend-icon="mdi-lock" :value="passwordRepeat" @change="setPasswordRepeat($event.target.value)"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
                </v-col>
            </v-row>
        </v-form>
    </dashboard-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewUser",
    computed: {
        ...mapGetters({
            new: 'usereditor/new',
            name: 'usereditor/name',
            email: 'usereditor/email',
            password: 'usereditor/password',
            passwordRepeat: 'usereditor/passwordRepeat'
        }),
        title: function() {
            return this.new ? "Nieuwe Gebruiker": "Bewerk Gebruiker";
        }
    },
    methods: {
        ...mapActions({
            setName: 'usereditor/setName',
            setEmail: 'usereditor/setEmail',
            setPassword: 'usereditor/setPassword',
            setPasswordRepeat: 'usereditor/setPasswordRepeat'
        })
    },
    async mounted() {
        await this.$store.dispatch('usereditor/reset');
        if (this.$route.params.id)
            await this.$store.dispatch('usereditor/load', {id: this.$route.params.id});
    }
}
</script>

<style scoped>

</style>
